import React from 'react';
import PropTypes from 'prop-types';
import {
  Spinner,
} from 'react-bootstrap';
import './style.scss';

const LoadingBlock = ({ enabled }) => {
  if (enabled) {
    return (
      <div className="loading-backdrop">
        {/* <div className="loading" /> */}
        <Spinner className="loading-block-spinner-style" animation="border" />
      </div>
    );
  }

  return null;
};

LoadingBlock.defaultProps = {
  enabled: false,
};

LoadingBlock.propTypes = {
  enabled: PropTypes.bool,
};

export default LoadingBlock;
