import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import appConfig from '@config/app';
import * as Sentry from '@sentry/react';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: appConfig.sentryDSN,
  environment: appConfig.env,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
