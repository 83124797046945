import { handleActions } from 'redux-actions';

import {
// getProjectAction,
} from './DashboardActionConfig';

// reducers
const defaultState = {
  project: {},
};

const reducer = handleActions({
  // [getProjectAction](state, { payload }) {
  //   return {
  //     ...state,
  //     project: payload,
  //   };
  // },
}, defaultState);

export default reducer;
