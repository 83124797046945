import { handleActions } from 'redux-actions';

import {
  loginAction,
  logoutAction,
  clearUserTokenAction,
  // forgot password
  getUserDetailsAction,
  updateInformationAction,

  setUserDetailsAction,
} from './AuthActionConfig';

// reducers
const defaultState = {
  accessToken: null,
  user: {},
  errors: {},

  // // temporary default user
  // currentUser: {
  //   id: DEFAULT_USER.id,
  //   email: DEFAULT_USER.email,
  //   name: DEFAULT_USER.name,
  //   role: DEFAULT_USER.role,
  //   thumb: DEFAULT_USER.thumb,
  // },
};

const reducer = handleActions({
  [loginAction](state, { payload }) {
    return {
      ...state,
      accessToken: payload.access_token,
      user: payload.user,
    };
  },

  [logoutAction]() {
    return defaultState;
  },

  [getUserDetailsAction](state, { payload }) {
    return {
      ...state,
      user: payload,
    };
  },

  [updateInformationAction](state, { params }) {
    const { user } = state;
    user.name = params.name;
    user.email = params.email;
    user.image = params.image;

    return { ...state, user: { ...user } };
  },

  [setUserDetailsAction](state, { payload }) {
    return {
      ...state,
      user: {
        ...state.user,
        first_name: payload.first_name,
        middle_name: payload.middle_name,
        last_name: payload.last_name,
      },
    };
  },
  [clearUserTokenAction]() {
    return defaultState;
  },
}, defaultState);

export default reducer;
