/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const loginAction = createAction('CLIENT_LOGIN');
export const logoutAction = createAction('CLIENT_LOGOUT');
export const clearUserTokenAction = createAction('CLIENT_CLEAR_TOKEN');
export const getUserDetailsAction = createAction('CLIENT_GET_USER_DETAILS');
export const updateInformationAction = createAction('CLIENT_UPDATE_INFORMATION');
export const sendCodeAction = createAction('CLIENT_SEND_CODE');
export const verifyCodeAction = createAction('CLIENT_VERIFY_CODE');
export const changePasswordAction = createAction('CLIENT_CHANGE_PASSWORD');
export const initialChangePasswordAction = createAction('CLIENT_INITIAL_CHANGE_PASSWORD');

export const setUserDetailsAction = createAction('CLIENT_SET_USER_DETAILS');
