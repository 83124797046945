
export default {
  color: {
    primary: '#019b76',
    success: '#37c936',
    danger: '#ff3c7e',
    warning: '#ffcc00',
    info: '#0f9aee',
    dark: '#515365',
    gray: '#ebeef6',
    white: '#ffffff',
  },
};
