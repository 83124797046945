export default {
  /*
    Menu Types:
    "menu-default", "menu-sub-hidden", "menu-hidden"
  */
  defaultMenuType: 'menu-default',

  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,

  defaultLocale: 'en',
  localeOptions: [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'es', name: 'Español', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  ],

  searchPath: '/app/pages/search',
  servicePath: 'https://api.coloredstrategies.com',

  /*
    Color Options:
    "light.purple", "light.blue", "light.green", "light.orange", "light.red",
    "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
  */
  isMultiColorActive: true,
  defaultColor: 'light.purple',
  defaultDirection: 'ltr',
  isDarkSwitchActive: false,
  themeColorStorageKey: '__theme_color',
  themeRadiusStorageKey: '__theme_radius',
  isDemo: false,

  DIMENSION: {
    Desktop: 'desktop',
    Tablet: 'tablet',
    Mobile: 'mobile',
  },
  LAYOUT: {
    Fluid: 'fluid',
    Boxed: 'boxed',
  },
  RADIUS: {
    Rounded: 'rounded',
    Standard: 'standard',
    Flat: 'flat',
  },
  THEME_COLOR: {
    LightBlue: 'light-blue',
    DarkBlue: 'dark-blue',
    LightRed: 'light-red',
    DarkRed: 'dark-red',
    LightGreen: 'light-green',
    DarkGreen: 'dark-green',
    LightPurple: 'light-purple',
    DarkPurple: 'dark-purple',
    LightPink: 'light-pink',
    DarkPink: 'dark-pink',
  },
  NAV_COLOR: {
    Default: 'default',
    Light: 'light',
    Dark: 'dark',
  },
  MENU_PLACEMENT: {
    Vertical: 'vertical',
    Horizontal: 'horizontal',
  },
  MENU_BEHAVIOUR: {
    Pinned: 'pinned',
    Unpinned: 'unpinned',
  },
  USER_ROLE: {
    Admin: 'admin',
    Editor: 'editor',
  },
};
