import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import AuthReducer from '@reducers/auth/AuthReducer';
import LoadingReducer from '@reducers/loading/LoadingReducer';
import MenuReducer from '@reducers/menu/MenuReducer';
import SettingsReducer from '@reducers/settings/SettingsReducer';
import LayoutSettingsReducer from '@reducers/layout-settings/LayoutSettingsReducer';
import DashboardReducer from '@reducers/dashboard/DashboardReducer';
import ProjectReducer from '@reducers/project/ProjectReducer';
import ReleasesReducer from '@reducers/releases/ReleasesReducer';

import { reducer as toastrReducer } from 'react-redux-toastr';

import persistConfig from '@config/redux-persist';

const reducers = combineReducers({
  Auth: persistReducer(persistConfig.auth, AuthReducer),
  Loading: LoadingReducer,
  Menu: MenuReducer,
  Settings: SettingsReducer,
  LayoutSettings: LayoutSettingsReducer,
  Dashboard: DashboardReducer,
  Project: ProjectReducer,
  Releases: ReleasesReducer,
  toastr: toastrReducer,
});

export default reducers;
