/* eslint-disable no-console */
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import { IS_AUTH_GUARD_ACTIVE, DEFAULT_PATHS } from '@config/config.js';

// eslint-disable-next-line
import packageJson from '@root/package.json';
const packageName = packageJson.name;

const currencyFormat = (value, format = '0,0.00', currency = '₱', pad = true) => `${currency}${pad ? ' ' : ''}${numeral(value).format(format)}`;

const shortDateFormat = date => moment(date).format('L');

const serverDateFormat = date => moment(date).format('YYYY-MM-DD');

// Replace underscore with spaces and capitalize words
const humanize = (str) => {
  const removedUnderscore = str.split('_');
  const capitalizedWords = removedUnderscore.map(
    word => word.slice(0, 1).toUpperCase() + word.slice(1),
  );
  const joinedWords = capitalizedWords.join(' ');
  // Capitalize words that has slash symbol
  const removeSlashed = joinedWords.split('/');
  const mappedSlashed = removeSlashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  // Capitalize words that has dash symbol
  const joinedMappedWords = mappedSlashed.join('/');
  const removeDashed = joinedMappedWords.split('-');
  const mappedDashed = removeDashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  return mappedDashed.join('-');
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // ** same as Math.pow(k, i)
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

const time24HourFormat = date => moment(date).format('HH:mm');

const dateFormat = date => moment(date).format('YYYY-MM-DD');

const timestampToMonthDay = date => moment(date).format('MMM. DD');

const timestampToDate = (date, showYear = false) => {
  const parsedDate = moment(date);
  if (showYear) return parsedDate.format('MMM. D, YYYY');

  const now = moment();
  return now.isSame(date, 'year') ? parsedDate.format('MMM. D') : parsedDate.format('MMM. D, YYYY');
};

const timestampToDayOfWeek = date => moment(date).format('dddd');

const timestampToDateTime = date => moment(date).format('MMMM D, YYYY h:mm a');

const normalizeMobile = (mobile) => {
  let normalizedMobile = mobile;
  if (mobile.startsWith('0')) {
    normalizedMobile = normalizedMobile.replace('0', '');
  } else if (mobile.startsWith('63')) {
    normalizedMobile = normalizedMobile.replace('63', '');
  } else if (mobile.startsWith('+63')) {
    normalizedMobile = normalizedMobile.replace('+63', '');
  }
  return normalizedMobile;
};

const getCurrentVersion = () => localStorage.getItem(`${packageName}-version`);

const isLatestVersion = currentVersion => currentVersion === getCurrentVersion();

const setLatestVersion = version => localStorage.setItem(`${packageName}-version`, version);

const randomStringGenerator = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

// ACORN DEFAULT HELPERS

const userHasRole = (routeRoles, userRole) => {
  if (!userRole) return false;
  return routeRoles.includes(userRole);
};

const clearRoute = (route) => {
  const item = {};
  [ 'path', 'to', 'exact', 'component', 'redirect' ].forEach((key) => {
    if (route[key]) item[key] = route[key];
  });
  return item;
};
const clearMenuItem = (menuItem) => {
  const item = {};
  [ 'path', 'label', 'icon', 'isExternal', 'subs', 'mega', 'megaParent' ].forEach((key) => {
    if (menuItem[key]) item[key] = menuItem[key];
  });
  return item;
};

const capitalizeEveryWord = inputString => _.startCase(inputString);

const getTextColor = (backgroundColor) => {
  // Convert the hex color to RGB
  const hexColor = backgroundColor.replace('#', '');
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate brightness using a simple formula
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Decide text color based on brightness
  return brightness > 150 ? '#1F2525' : '#FBF9F5';
};

export const convertToRoutes = ({
  data = [],
  isLogin = false,
  userRole = null,
  authGuardActive = IS_AUTH_GUARD_ACTIVE,
  unauthorizedPath = DEFAULT_PATHS.UNAUTHORIZED,
  loginPath = DEFAULT_PATHS.LOGIN,
  invalidAccessPath = DEFAULT_PATHS.INVALID_ACCESS,
  noLayout = false,
}) => {
  let items = [];
  if (Array.isArray(data)) {
    items = data;
  } else {
    items = [ ...data.sidebarItems, ...data.mainMenuItems ];
  }

  const routes = [];
  return () => {
    const itemMapper = (item) => {
      const tempItem = { ...item };
      if (item.hideInRoute) return itemMapper({});

      if (item.subs) tempItem.exact = true;

      /* Authentication Guard */
      if (authGuardActive) {
        if (tempItem.roles) tempItem.protected = true;
        if (tempItem.publicOnly) {
          delete tempItem.roles;
          delete tempItem.protected;
        }
        if (tempItem.protected) {
          if (!isLogin) {
            tempItem.redirect = true;
            tempItem.to = {
              pathname: loginPath,
              state: { from: tempItem.to },
            };
          } else if (tempItem.roles) {
            if (!userHasRole(tempItem.roles, userRole)) {
              tempItem.redirect = true;
              tempItem.to = {
                pathname: unauthorizedPath,
                state: { from: tempItem.to },
              };
            }
          }
        } else if (tempItem.publicOnly && isLogin) {
          tempItem.redirect = true;
          tempItem.to = {
            pathname: invalidAccessPath,
            state: { from: tempItem.to },
          };
        }
      }

      if (Object.keys(tempItem).length > 0 && !item.isExternal) {
        if (item.noLayout && noLayout) {
          routes.push(clearRoute({ ...tempItem, exact: true }));
        }
        if (!noLayout && item.noLayout !== true) {
          routes.push(clearRoute(tempItem));
        }
      }

      if (item.subs) {
        return item.subs.map((sub) => {
          const controlledSub = { ...sub, path: tempItem.to + sub.to };
          if (authGuardActive) {
            if (tempItem.protected) controlledSub.protected = true;
            if (tempItem.roles) {
              if (!sub.roles) controlledSub.roles = tempItem.roles;
              else {
                // common roles..
                controlledSub.roles = tempItem.roles.filter(x => sub.roles.includes(x));

                if (controlledSub.roles && controlledSub.roles.length === 0) {
                  controlledSub.inaccessible = true;
                  console.log(
                    `This route(${controlledSub.to}) is inaccessible. Please check the roles you defined in the hierarchical structure.`,
                    controlledSub,
                  );
                }
              }
            } else if (tempItem.publicOnly) {
              controlledSub.publicOnly = true;
            }
            if (controlledSub.roles && controlledSub.roles.length === 0) delete controlledSub.roles;

            if (!controlledSub.inaccessible) return itemMapper(controlledSub);
            return itemMapper({});
          }
          return itemMapper(controlledSub);
        });
      }
      return tempItem;
    };
    items.map(itemMapper);
    return routes;
  };
};

export const convertToMenuItems = ({
  data = [], authGuardActive = IS_AUTH_GUARD_ACTIVE, isLogin = false, userRole = null,
}) => {
  let items = [];
  if (Array.isArray(data)) {
    items = data;
  } else {
    items = [ ...data.sidebarItems, ...data.mainMenuItems ];
  }

  const itemMapper = (item) => {
    const tempItem = { ...item };

    if (authGuardActive) {
      /* Authentication Guard */
      if (tempItem.roles) tempItem.protected = true;

      if (tempItem.publicOnly) {
        delete tempItem.roles;
        delete tempItem.protected;
      }

      if (tempItem.protected) {
        if (!isLogin) {
          return {};
        }
        if (tempItem.roles) {
          if (!userHasRole(tempItem.roles, userRole)) {
            return {};
          }
        }
      } else if (tempItem.publicOnly && isLogin) {
        return {};
      }
    }

    if (tempItem.subs) {
      tempItem.subs = item.subs
        .map((sub) => {
          const controlledSub = { ...sub, path: tempItem.to + sub.to };
          if (tempItem.mega || tempItem.megaParent) controlledSub.megaParent = true;

          if (authGuardActive) {
            if (tempItem.protected) controlledSub.protected = true;

            if (tempItem.roles) {
              if (!sub.roles) controlledSub.roles = tempItem.roles;
              else {
                // common roles..
                controlledSub.roles = tempItem.roles.filter(x => sub.roles.includes(x));

                if (controlledSub.roles && controlledSub.roles.length === 0) {
                  controlledSub.inaccessible = true;
                  console.log(
                    `This menu item(${controlledSub.to}) is inaccessible. Please check the roles you defined in the hierarchical structure.`,
                    controlledSub,
                  );
                }
              }
            } else if (tempItem.publicOnly) {
              controlledSub.publicOnly = true;
            }
            if (controlledSub.roles && controlledSub.roles.length === 0) delete controlledSub.roles;

            if (!controlledSub.inaccessible) return itemMapper(controlledSub);
            return itemMapper({});
          }
          return itemMapper(controlledSub);
        })
        .filter(x => Object.keys(x).length > 0);

      if (tempItem.subs.length === 0) delete tempItem.subs;
    }
    if (tempItem.label && !item.hideInMenu) return clearMenuItem(tempItem);
    return {};
  };
  return items.map(itemMapper).filter(x => Object.keys(x).length > 0);
};


export const getRoutes = ({ data, isLogin, userRole }) => convertToRoutes({
  data,
  isLogin,
  userRole,
  authGuardActive: IS_AUTH_GUARD_ACTIVE,
  unauthorizedPath: DEFAULT_PATHS.UNAUTHORIZED,
  loginPath: DEFAULT_PATHS.LOGIN,
  invalidAccessPath: DEFAULT_PATHS.INVALID_ACCESS,
  noLayout: false,
})();

export const getLayoutlessRoutes = ({ data }) => convertToRoutes({ data, noLayout: true })();
export const getMenuItems = ({ data, isLogin, userRole }) => convertToMenuItems({
  data, isLogin, userRole, authGuardActive: IS_AUTH_GUARD_ACTIVE,
});

export {
  currencyFormat,
  shortDateFormat,
  humanize,
  dateFormat,
  time24HourFormat,
  timestampToMonthDay,
  timestampToDate,
  timestampToDayOfWeek,
  timestampToDateTime,
  normalizeMobile,
  serverDateFormat,
  getCurrentVersion,
  isLatestVersion,
  setLatestVersion,
  capitalizeEveryWord,
  randomStringGenerator,
  formatBytes,
  getTextColor,
};
