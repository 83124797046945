import { handleActions } from 'redux-actions';

import {
  setUATLinkAction,
  setCurrentTestCaseAction,
} from './ReleasesActionConfig';

// reducers
const defaultState = {
  uatLink: null,
  currentTestCase: {
    name: null,
    id: null,
  },
};

const reducer = handleActions({
  [setUATLinkAction](state, { payload }) {
    return { ...state, uatLink: payload };
  },
  [setCurrentTestCaseAction](state, { payload }) {
    return { ...state, currentTestCase: payload };
  },
}, defaultState);

export default reducer;
