/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const setColorAction = createAction('LAYOUT_SETTINGS_SET_COLOR');
export const settingsChangeColorAction = createAction('LAYOUT_SETTINGS_CHANGE_COLOR');
export const setThemeValuesAction = createAction('LAYOUT_SETTINGS_SET_THEME_VALUES');
export const settingsChangeRadiusAction = createAction('LAYOUT_SETTINGS_CHANGE_RADIUS');
export const settingsChangeNavColorAction = createAction('LAYOUT_SETTINGS_CHANGE_NAV_COLOR');
export const settingsChangeLayoutAction = createAction('LAYOUT_SETTINGS_CHANGE_LAYOUT');
export const settingsResetLayoutAction = createAction('LAYOUT_SETTINGS_RESET_LAYOUT');

export const layoutShowingNavMenuAction = createAction('LAYOUT_SETTINGS_SHOWING_NAV_MENU');
export const scrollSpySetItemsAction = createAction('LAYOUT_SETTINGS_SCROLL_SPY_ITEMS');
