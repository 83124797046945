
import auth from './auth.json';
import profileSettings from './profileSettings.json';
import general from './general.json';
import menu from './menu.json';
import dashboard from './dashboard.json';
import tickets from './tickets.json';
import releases from './releases.json';
import bugs from './bugs.json';
import projects from './projects.json';

export default {
  auth,
  profileSettings,
  general,
  menu,
  dashboard,
  tickets,
  releases,
  bugs,
  projects,
};
