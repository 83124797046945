import React, { Suspense, lazy, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import uuid from 'react-uuid';

import CacheBuster from '@shared/utils/CacheBuster';

import { LoadingBlock } from '@shared/utils';
import ReduxToastr from 'react-redux-toastr';
import DialogService from '@services/DialogService';
import LocaleService from '@services/LocaleService';
import { store, persistor } from './store';

const LazyAppRouter = lazy(() => import('@app/AppRouter'));
const App = () => {
  useEffect(() => {
    const deviceUuid = localStorage.getItem('DEVICE_UUID');

    if (!deviceUuid) {
      try {
        localStorage.setItem('DEVICE_UUID', uuid());
      } catch (error) {
        const { title, message } = LocaleService.getTranslation('general.error.localStorage');
        DialogService.alert({ title, message });
      }
    }
  }, []);

  return (
    <CacheBuster>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<LoadingBlock />}>
            <LazyAppRouter />
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-left"
              getState={state => state.toastr} // This is the default
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </Suspense>
        </PersistGate>
      </Provider>
    </CacheBuster>
  );
};

export default App;
