import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';
import { DEFAULT_SETTINGS } from '@config/config.js';
import {
  setContainerClassnamesAction,
  addContainerClassnameAction,
  clickMobileMenuAction,
  changeDefaultClassesAction,
  changeHasSubItemStatusAction,

  menuChangePlacementAction,
  menuChangePlacementStatusAction,
  menuChangeBehaviourAction,
  menuChangeBehaviourStatusAction,
  menuChangePinButtonEnableAction,
  menuChangeAttrMenuAnimateAction,
  menuChangeAttrMobileAction,
  menuChangeCollapseAllAction,
  menuChangeNavClassesAction,
  menuChangeBreakpointsAction,
  menuResetBreakpointsAction,
  menuChangeUseSidebarAction,
  menuResetUseSidebarAction,
  menuChangeMenuPaddingAction,
} from './MenuActionConfig';

const {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} = constantsConfig;

const BREAKPOINTS = {
  sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400,
};

// reducers
const defaultState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false

  behaviour: DEFAULT_SETTINGS.MENU_BEHAVIOUR,
  placement: DEFAULT_SETTINGS.MENU_PLACEMENT,
  useSidebar: DEFAULT_SETTINGS.USE_SIDEBAR,
  pinButtonEnable: true,
  placementStatus: {},
  behaviourStatus: {},
  navClasses: {},
  attrMobile: false,
  attrMenuAnimate: '',
  collapseAll: false,
  breakpoints: {
    verticalUnpinned: BREAKPOINTS.xxl,
    verticalMobile: BREAKPOINTS.lg,
    horizontalMobile: BREAKPOINTS.lg,
  },
  menuPadding: 0,
};

const reducer = handleActions({
  [setContainerClassnamesAction](state, { payload }) {
    const { strCurrentClasses, selectedMenuHasSubItems } = payload;
    let { clickIndex } = payload;

    const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter(x => x !== '') : '';
    let nextClasses = '';

    if (!selectedMenuHasSubItems) {
      if (currentClasses.includes('menu-default') && (clickIndex % 4 === 0 || clickIndex % 4 === 3)) {
        clickIndex = 1;
      }
      if (currentClasses.includes('menu-sub-hidden') && (clickIndex % 4 === 2)) {
        clickIndex = 0;
      }
      if (currentClasses.includes('menu-hidden') && (clickIndex % 4 === 2 || clickIndex % 4 === 3)) {
        clickIndex = 0;
      }
    }

    if (clickIndex % 4 === 0) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden';
      }
      clickIndex = 0;
    } else if (clickIndex % 4 === 1) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default sub-hidden';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary';
      }
    } else if (clickIndex % 4 === 2) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default main-hidden sub-hidden';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
      }
    } else if (clickIndex % 4 === 3) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default sub-hidden';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden sub-show-temporary';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary';
      }
    }
    if (currentClasses.includes('menu-mobile')) {
      nextClasses += ' menu-mobile';
    }

    return {
      ...state,
      containerClassnames: nextClasses,
      menuClickCount: clickIndex,
    };
  },
  [addContainerClassnameAction](state, { payload }) {
    const { classname, strCurrentClasses } = payload;
    const newClasses = !strCurrentClasses.indexOf(classname) > -1 ? `${strCurrentClasses} ${classname}` : strCurrentClasses;

    return { ...state, containerClassnames: newClasses };
  },
  [clickMobileMenuAction](state, { payload }) {
    const { strCurrentClasses } = payload;

    const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter(x => x !== '' && x !== 'sub-show-temporary') : '';
    let nextClasses = '';

    if (currentClasses.includes('main-show-temporary')) {
      nextClasses = (currentClasses.filter(x => x !== 'main-show-temporary')).join(' ');
    } else {
      nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
    }

    return {
      ...state,
      containerClassnames: nextClasses,
      menuClickCount: 0,
    };
  },
  [changeDefaultClassesAction](state, { payload }) {
    return { ...state, containerClassnames: payload };
  },
  [changeHasSubItemStatusAction](state, { payload }) {
    return { ...state, selectedMenuHasSubItems: payload };
  },
  [menuChangePlacementAction](state, { payload }) {
    return { ...state, placement: payload };
  },
  [menuChangePlacementStatusAction](state, { payload }) {
    return {
      ...state,
      placementStatus: payload,
    };
  },
  [menuChangeBehaviourAction](state, { payload }) {
    return { ...state, behaviour: payload };
  },
  [menuChangeBehaviourStatusAction](state, { payload }) {
    return { ...state, behaviourStatus: payload };
  },
  [menuChangePinButtonEnableAction](state, { payload }) {
    return { ...state, pinButtonEnable: payload };
  },
  [menuChangeAttrMenuAnimateAction](state, { payload }) {
    return { ...state, attrMenuAnimate: payload };
  },
  [menuChangeAttrMobileAction](state, { payload }) {
    return { ...state, attrMobile: payload };
  },
  [menuChangeCollapseAllAction](state, { payload }) {
    return { ...state, collapseAll: payload };
  },
  [menuChangeNavClassesAction](state, { payload }) {
    return { ...state, navClasses: payload };
  },
  [menuChangeBreakpointsAction](state, { payload }) {
    const { breakpoints } = state;
    return {
      ...state,
      breakpoints: {
        ...breakpoints,
        ...payload,
      },
    };
  },
  [menuResetBreakpointsAction](state) {
    return {
      ...state,
      breakpoints: {
        verticalUnpinned: BREAKPOINTS.xxl,
        verticalMobile: BREAKPOINTS.lg,
        horizontalMobile: BREAKPOINTS.lg,
      },
    };
  },
  [menuChangeUseSidebarAction](state, { payload }) {
    return { ...state, useSidebar: payload };
  },
  [menuResetUseSidebarAction](state) {
    return { ...state, useSidebar: DEFAULT_SETTINGS.useSidebar };
  },
  [menuChangeMenuPaddingAction](state, { payload }) {
    return { ...state, menuPadding: payload };
  },
}, defaultState);

export default reducer;
