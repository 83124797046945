/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getClickUpTaskListAction = createAction('CLIENT_PROJECT_GET_CLICK_UP_TASK_LIST');
export const getStatusPointsAction = createAction('CLIENT_PROJECT_GET_STATUS_POINTS');
export const getTaskCommentsAction = createAction('CLIENT_PROJECT_GET_TASK_COMMENTS');
export const createTaskCommentsAction = createAction('CLIENT_PROJECT_CREATE_TASK_COMMENTS');
export const getClickUpTaskAction = createAction('CLIENT_PROJECT_GET_CLICK_UP_TASK');
export const getEpicListAction = createAction('CLIENT_PROJECT_GET_EPIC_LIST');
export const getBacklogListAction = createAction('CLIENT_PROJECT_GET_BACKLOG_LIST');

export const setCurrentProjectAction = createAction('CLIENT_PROJECT_SET_CURRENT_PROJECT');
