/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { isLatestVersion, setLatestVersion } from '@shared/helpers';

import LoadingBlock from '../LoadingBlock';

const CacheBuster = ({ children }) => {
  const [ loading, setLoading ] = useState(false);

  const refreshCacheAndReload = () => {
    console.info('Clearing cache and hard reloading...');
    if (window.caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const getVersion = async () => {
    const response = await axios.get('/meta.json').catch(() => {
      console.log('Error fetching metadata');
    });

    setLoading(false);

    if (!response) return null;
    const meta = response.data;

    return meta.timestamp.toString();
  };

  useEffect(() => {
    const didMount = async () => {
      const currentVersion = await getVersion();

      if (!isLatestVersion(currentVersion)) {
        console.log(`We have a new version - ${currentVersion}. Should force refresh`);
        setLatestVersion(currentVersion);
        refreshCacheAndReload();
        return;
      }

      console.log(`You already have the latest version - ${currentVersion}. No cache refresh needed.`);
    };

    didMount();
  }, []);
  if (loading) return <LoadingBlock enabled />;

  return children;
};

CacheBuster.defaultProps = {
  children: null,
};

CacheBuster.propTypes = {
  children: PropTypes.any,
};

export default CacheBuster;
