import { handleActions } from 'redux-actions';

import {
  loginAction,
  getUserDetailsAction,
  logoutAction,
  clearUserTokenAction,
} from '../auth/AuthActionConfig';

import {
  getProjectReportListAction,
} from '../dashboard/DashboardActionConfig';

import {
  setCurrentProjectAction,
} from './ProjectActionConfig';

// reducers
const defaultState = {
  selectedProject: {},
  projectReportList: [],
  projectList: [],
  // this variable is used for setting the project in project-list
};

const reducer = handleActions({
  [loginAction](state, { payload }) {
    return {
      ...state,
      selectedProject: payload?.user?.projects
      && payload?.user?.projects.length === 1 ? payload?.user?.projects[0]
        : state.selectedProject,
      projectList: payload?.user?.projects,
    };
  },
  [getUserDetailsAction](state, { payload }) {
    return {
      ...state,
      selectedProject: payload?.projects
      && payload?.projects.length === 1 ? payload?.projects[0]
        : state.selectedProject,
      projectList: payload?.projects,
    };
  },
  [getProjectReportListAction](state, { payload }) {
    return {
      ...state,
      projectReportList: payload?.data,
    };
  },
  [setCurrentProjectAction](state, { payload }) {
    return {
      ...state,
      selectedProject: payload,
    };
  },
  [logoutAction](state) {
    return {
      ...state,
      selectedProject: {},
      projectList: [],
    };
  },
  [clearUserTokenAction](state) {
    return {
      ...state,
      selectedProject: {},
      projectList: [],
    };
  },
}, defaultState);

export default reducer;
