/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getProjectReleasesListAction = createAction('CLIENT_PROJECT_RELEASES_GET_LIST');
export const getTestCaseListAction = createAction('CLIENT_PROJECT_RELEASES_GET_TEST_CASE_LIST');

export const setUATLinkAction = createAction('CLIENT_PROJECT_RELEASES_SET_UAT_LINK');
export const setCurrentTestCaseAction = createAction('CLIENT_PROJECT_RELEASES_SET_CURRENT_TEST_CASE');
export const getTestCaseDetailsAction = createAction('CLIENT_PROJECT_RELEASES_GET_TEST_CASE_DETAILS');
export const updateTestCaseTaskAction = createAction('CLIENT_PROJECT_RELEASES_UPDATE_TEST_CASE_TASK');
export const updateTaskCustomFieldAction = createAction('CLIENT_PROJECT_RELEASES_UPDATE_TASK_CUSTOM_FIELD');
