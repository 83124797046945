import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';

import { DEFAULT_SETTINGS } from '@config/config.js';

import {
  setColorAction,
  // settingsChangeColorAction,
  setThemeValuesAction,
  settingsChangeRadiusAction,
  settingsChangeNavColorAction,
  settingsChangeLayoutAction,
  settingsResetLayoutAction,

  layoutShowingNavMenuAction,

  scrollSpySetItemsAction,
} from './LayoutSettingsActionConfig';

const { defaultLocale, localeOptions } = constantsConfig;

const getComputedValues = () => {
  const rootStyle = window.getComputedStyle(document.body);
  return {
    primary: rootStyle.getPropertyValue('--primary').trim(),
    secondary: rootStyle.getPropertyValue('--secondary').trim(),
    tertiary: rootStyle.getPropertyValue('--tertiary').trim(),
    quaternary: rootStyle.getPropertyValue('--quaternary').trim(),
    body: rootStyle.getPropertyValue('--body').trim(),
    alternate: rootStyle.getPropertyValue('--alternate').trim(),
    lightText: rootStyle.getPropertyValue('--light-text').trim(),
    warning: rootStyle.getPropertyValue('--warning').trim(),
    danger: rootStyle.getPropertyValue('--danger').trim(),
    success: rootStyle.getPropertyValue('--success').trim(),
    info: rootStyle.getPropertyValue('--info').trim(),

    font: rootStyle.getPropertyValue('--font').trim(),
    fontHeading: rootStyle.getPropertyValue('--font-heading').trim(),

    background: rootStyle.getPropertyValue('--background').trim(),
    foreground: rootStyle.getPropertyValue('--foreground').trim(),
    separator: rootStyle.getPropertyValue('--separator').trim(),
    separatorLight: rootStyle.getPropertyValue('--separator-light').trim(),

    primaryrgb: rootStyle.getPropertyValue('--primary-rgb').trim(),
    secondaryrgb: rootStyle.getPropertyValue('--secondary-rgb').trim(),
    tertiaryrgb: rootStyle.getPropertyValue('--tertiary-rgb').trim(),
    quaternaryrgb: rootStyle.getPropertyValue('--quaternary-rgb').trim(),

    transitionTimeShort: rootStyle.getPropertyValue('--transition-time-short').trim().replace('ms', ''),
    transitionTime: rootStyle.getPropertyValue('--transition-time').trim().replace('ms', ''),
    navSizeSlim: rootStyle.getPropertyValue('--nav-size-slim').trim(),

    borderRadiusXl: rootStyle.getPropertyValue('--border-radius-xl').trim(),
    borderRadiusLg: rootStyle.getPropertyValue('--border-radius-lg').trim(),
    borderRadiusMd: rootStyle.getPropertyValue('--border-radius-md').trim(),
    borderRadiusSm: rootStyle.getPropertyValue('--border-radius-sm').trim(),
    spacingHorizontal: rootStyle.getPropertyValue('--main-spacing-horizontal').trim(),

    sm: rootStyle.getPropertyValue('--sm').trim(),
    md: rootStyle.getPropertyValue('--md').trim(),
    lg: rootStyle.getPropertyValue('--lg').trim(),
    xl: rootStyle.getPropertyValue('--xl').trim(),
    xxl: rootStyle.getPropertyValue('--xxl').trim(),
    direction: 'ltr',
  };
};

// reducers
const defaultState = {
  locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0)
    ? localStorage.getItem('currentLanguage')
    : defaultLocale,
  color: DEFAULT_SETTINGS.COLOR,
  layout: DEFAULT_SETTINGS.LAYOUT,
  radius: DEFAULT_SETTINGS.RADIUS,
  navColor: DEFAULT_SETTINGS.NAV_COLOR,
  themeValues: getComputedValues(),
  color_pallete: DEFAULT_SETTINGS.COLOR,

  showingNavMenu: '',

  scrollSpy: {
    items: [],
  },
};

const reducer = handleActions({
  // getHomeAction = Variable| state, action(payload)
  [setColorAction](state, { payload }) {
    return {
      ...state,
      color: payload,
    };
  },
  [setThemeValuesAction](state) {
    return {
      ...state,
      themeValues: getComputedValues(),
    };
  },
  [settingsChangeRadiusAction](state, { payload }) {
    return {
      ...state,
      radius: payload,
    };
  },
  [settingsChangeNavColorAction](state, { payload }) {
    return {
      ...state,
      navColor: payload,
    };
  },
  [settingsChangeLayoutAction](state, { payload }) {
    return {
      ...state,
      layout: payload,
    };
  },
  [settingsResetLayoutAction](state) {
    return {
      ...state,
      layout: DEFAULT_SETTINGS.LAYOUT,
    };
  },
  [layoutShowingNavMenuAction](state, { payload }) {
    return {
      ...state,
      showingNavMenu: payload,
    };
  },
  [scrollSpySetItemsAction](state, { payload }) {
    return {
      ...state,
      scrollSpy: {
        items: payload,
      },
    };
  },
}, defaultState);

export default reducer;
