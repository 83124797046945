/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
// Component -> Saga -> Redux
// actionNameSagaAction - Saga (Component to Saga)
// actionNameReduxAction - Redux (Saga to Redux or Component to Redux)
export const setContainerClassnamesAction = createAction('SRS_MENU_SET_CONTAINER_CLASSNAMES');
export const addContainerClassnameAction = createAction('SRS_MENU_ADD_CONTAINER_CLASSNAME');
export const clickMobileMenuAction = createAction('SRS_MENU_CLICK_MOBILE_MENU');
export const changeDefaultClassesAction = createAction('SRS_MENU_CHANGE_DEFAULT_CLASSES');
export const changeHasSubItemStatusAction = createAction('SRS_MENU_CHANGE_HAS_SUB_ITEM_STATUS');

// ACORN ACTIONS CONFIG
export const menuChangePlacementAction = createAction('ACORN_MENU_CHANGE_PLACEMENT');
export const menuChangePlacementStatusAction = createAction('ACORN_MENU_CHANGE_PLACEMENT_STATUS');
export const menuChangeBehaviourAction = createAction('ACORN_MENU_CHANGE_BEHAVIOUR');
export const menuChangeBehaviourStatusAction = createAction('ACORN_MENU_CHANGE_BEHAVIOUR_STATUS');
export const menuChangePinButtonEnableAction = createAction('ACORN_MENU_CHANGE_PIN_BUTTON_ENABLE');
export const menuChangeAttrMenuAnimateAction = createAction('ACORN_MENU_CHANGE_ATTR_MENU_ANIMATE');
export const menuChangeAttrMobileAction = createAction('ACORN_MENU_CHANGE_ATTR_MOBILE');
export const menuChangeCollapseAllAction = createAction('ACORN_MENU_CHANGE_COLLAPSE_ALL');
export const menuChangeNavClassesAction = createAction('ACORN_MENU_CHANGE_NAV_CLASSES');
export const menuChangeBreakpointsAction = createAction('ACORN_MENU_CHANGE_BREAKPOINTS');
export const menuResetBreakpointsAction = createAction('ACORN_MENU_RESET_BREAKPOINTS');
export const menuChangeUseSidebarAction = createAction('ACORN_MENU_CHANGE_USE_SIDEBAR');
export const menuResetUseSidebarAction = createAction('ACORN_MENU_RESET_USE_SIDEBAR');
export const menuChangeMenuPaddingAction = createAction('ACORN_MENU_CHANGE_MENU_PADDING');
